import React from 'react';
import Modal from '../../../components/Modal';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Mississippi Delta (Including Northeast Louisiana)',
  cityModal: true,
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/atFcNLuiiQ0',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      In January 2019, we traveled across the Mississippi Delta. In Mississippi,
      we visited Greenville, Indianola, Cleveland, Moorhead, and Drew and in
      Louisiana, we visited Tallulah and Lake Providence. Since 2000, the
      overall population has declined by one-fifth across the Mississippi Delta,
      with the white population declining the most—by over one-third.
    </p>
    <p>
      This is an economically depressed region, with unemployment high at over
      18 percent, and particularly high among the black population, with nearly
      1 in 4 out of work. There are wide gaps in earnings by race with blacks
      earning approximately $13,000 less than whites in Sunflower and Washington
      counties in Mississippi. In East Carroll Parish of Louisiana, the wage gap
      between white and black people is nearly $18,000. The Delta could face a
      skills gap if it does not increase educational attainment for all
      residents. Delta communities are deeply segregated with different races
      and ethnicities largely living in different parts of the city creating
      instances where white people may not interact with black people at all. In
      the Delta, we hosted individual interviews across the region; small group
      discussions in Lake Providence, LA, and Greenville and Cleveland, MS; and
      a large community listening session in Tallulah, LA. We visited the
      Sunflower Freedom Project to talk with youth. In Greenville, MS, we
      conducted two focus groups of non-college-educated black and white
      residents from across the region. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
  </Modal>
);
